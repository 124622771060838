import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private http: HttpClient) { }
  isToastEnable: boolean = false;
  toastType = '';
  message = '';
  title = '';
  sucessToastType = 'success';
  sucessTitle = 'Success';
  errorToastType = 'error';
  errorTitle = 'Error';
  fileValidationMessage = 'Please upload the file.'
  validaitionTitle = 'Validation Error'

  Toast(message: string, errorToastType: string, errorTitle: string) {
    this.isToastEnable = true;
    this.message = message
    this.toastType = errorToastType;
    this.title = errorTitle;
  }

}
