import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from '../../models/config';
import { environment } from 'src/environments/environment';
import { ApiResult } from '../../models/CoreApi/ApiResult';
import { ResponseData } from '../../models/ResponseData';
import { AuthenticationResponseModel, ChangePassword, ForgotPassord, SetPassword,UserUpdate,getUserDetail } from '../../models/Authentication/AuthenticationResponseModel';
import { Role } from '../../models/Authentication/role';

const auth_Api = environment.serverUrl;
const token_Key = 'auth-token';
const data_Key = 'auth-data';
const email_key = 'auth-email';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  isAuthenticate: boolean = false;

  login(username: string, password: string): Observable<ApiResult<ResponseData<AuthenticationResponseModel>>> {
    return this.http.post<ApiResult<ResponseData<AuthenticationResponseModel>>>(
      auth_Api + config.Auth_Login,
      {
        username,
        password,
      },
      httpOptions
    );
  }
  signOut(): void {
    const valueToKeepUserId = localStorage.getItem('userId');
    const valueToKeepPassword = localStorage.getItem('password');
    const valueToKeepRememberMe = localStorage.getItem('rememberMe');
    localStorage.clear();
    if (valueToKeepUserId !== null && valueToKeepPassword != null && valueToKeepRememberMe != null) {
      localStorage.setItem('userId', valueToKeepUserId);
      localStorage.setItem('password', valueToKeepPassword);
      localStorage.setItem('rememberMe', valueToKeepRememberMe);
    }
  }
  public saveToken(token: string): void {
    localStorage.removeItem(token_Key);
    localStorage.setItem(token_Key, token);
  }

  public saveCredentialOnRememberMe(username: string, password: string, isRememberMe: boolean): void {
  
    if (isRememberMe) {
      localStorage.setItem('userId', username);
      localStorage.setItem('password', password);
      localStorage.setItem('rememberMe', JSON.stringify(isRememberMe));
    }
    else {
      localStorage.removeItem('userId');
      localStorage.removeItem('password');
      localStorage.removeItem('rememberMe');
    }
  }

  // public saveEamil(email: string): void {
  //   localStorage.removeItem(email_key);
  //   localStorage.setItem(email_key, email);
  // }
  public getToken(): string | null {
    return localStorage.getItem(token_Key);
  }
  // public getEmail(): string | null {
  //   return localStorage.getItem(email_key);
  // }
  public getUserRole(): string | null {
    var _data = localStorage.getItem(data_Key);
    if (_data != undefined && _data != null) {
      var parseData = JSON.parse(_data);
      return parseData.userRole;
    }
    return "";
  }
  public getUserData(): any {
    var _data = localStorage.getItem(data_Key);
    if (_data != undefined && _data != null) {
      var parseData = JSON.parse(_data);
      return parseData
    }
    return "";

  }
  public saveData(user: any): void {
    localStorage.removeItem(data_Key);
    localStorage.setItem(data_Key, JSON.stringify(user));
  }

  public getUser(): any {
    const user = localStorage.getItem(data_Key);
    if (user) {
      return JSON.parse(user);
    }
    return "";
  }

  public isDFP(): any {
    var _data = localStorage.getItem(data_Key);
    if (_data != undefined && _data != null) {
      var parseData = JSON.parse(_data);
       return parseData.isDfp;
    }
    return false;

  }


  public isAdmin(): any {
    var _data = localStorage.getItem(data_Key);
    if (_data != undefined && _data != null) {
      var parseData = JSON.parse(_data);
      if (parseData.userRole == Role.Admin) {
        return true;
      }
      else
        return false;
    }

  }

  resetPassword(confirmPassword: string, id: string, code: string): Observable<ApiResult<ResponseData<boolean>>> {
    let resetModel: SetPassword = new SetPassword();
    resetModel.NewPassword = confirmPassword;
    resetModel.id = id;
    resetModel.code = code;
    //let url='https://localhost:7284/api/Authentication/SetNewPassword'
    return this.http.post<ApiResult<ResponseData<boolean>>>(auth_Api + config.resetPassword, resetModel)
    // return this.http.post<ApiResult<ResponseData<boolean>>>(url, resetModel)
  }

  forgotPassword(userName: string): Observable<ApiResult<ResponseData<ForgotPassord>>> {
    let model: ForgotPassord = new ForgotPassord()
    model.email = userName;
    return this.http.post<ApiResult<ResponseData<ForgotPassord>>>(auth_Api + config.forgotPassword, model)

  }

  changePassword(model: ChangePassword): Observable<ApiResult<ResponseData<boolean>>> {
    let token = this.getToken();
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: token
    });
    return this.http.post<ApiResult<ResponseData<boolean>>>(auth_Api + config.changePassword, model, { headers: httpHeaders })
  }
  GetUserByIDForEditDetail(userid:string){
    let token=this.getToken();
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: token
    });

    let parms=new HttpParams();
    parms=parms.append("userid",userid);
    return this.http.get<ApiResult<ResponseData<getUserDetail>>>(auth_Api+config.GetUserByIDForEditDetail,{params:parms, headers:httpHeaders});
  }
  updateUserDetail(model: UserUpdate): Observable<ApiResult<ResponseData<boolean>>> {
    let token = this.getToken();
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: token
    });
    return this.http.post<ApiResult<ResponseData<boolean>>>(auth_Api + config.UpdateUsersById, model, { headers: httpHeaders })
  }
  AccountActivation(confirmPassword: string, id: string, code: string): Observable<ApiResult<ResponseData<boolean>>> {
    let resetModel: SetPassword = new SetPassword();
    resetModel.NewPassword = confirmPassword;
    resetModel.id = id;
    resetModel.code = code;
    //let url='https://localhost:7284/api/Authentication/SetNewPassword'
    return this.http.post<ApiResult<ResponseData<boolean>>>(auth_Api + config.AccountActivation, resetModel)
    // return this.http.post<ApiResult<ResponseData<boolean>>>(url, resetModel)
  }
}
