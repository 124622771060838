export interface AuthenticationResponseModel {
    firstName: string;
    lastName: string;
    userId: string;
    userName: string;
    emailId: string;
    userRole: string | null;
    isActive: boolean;
    token: string;
    expiration: string | null;
}

export class ChangePassword {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export class SetPassword {
   // newPassword: string;
   NewPassword: string;
   id:string;
   code:string;
}

export class ForgotPassord {
    email: string;
}
export class UserUpdate{
  Id:string;
  FirstName:string;
  LastName:string;
}
export class getUserDetail{
    id :string
    firstName :string
    lastName :string
    email :string
    isAdminAccess :boolean
    isActive :boolean
    companyName:string
}
