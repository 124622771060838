import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotfoundComponent } from './notfound/notfound.component';
import { authGuard } from './auth.guard';
import { Role } from './core/models/Authentication/role';

const routes: Routes = [
  // { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'auth', loadChildren: () => import("./modules/auth/auth.module").then(m => m.AuthModule) },
  { path: '', loadChildren: () => import("./modules/project/project.module").then(m => m.ProjectModule), canActivate: [authGuard] },
  { path: 'notification', loadChildren: () => import("./modules/notifications/notifications.module").then(m => m.NotificationsModule), canActivate: [authGuard] },
  { path: 'projects/dashboard', loadChildren: () => import("./modules/dashboard/dashboard.module").then(m => m.DashboardModule), canActivate: [authGuard] },
  { path: 'projects', loadChildren: () => import("./modules/project/project.module").then(m => m.ProjectModule), canActivate: [authGuard] },
  { path: 'projects/vendor', loadChildren: () => import("./modules/vendor/vendor.module").then(m => m.VendorModule), canActivate: [authGuard] },
  { path: 'projects/dataupdate', loadChildren: () => import("./modules/dataupdate/dataupdate.module").then(m => m.DataupdateModule), canActivate: [authGuard] },
  { path: 'projects/quality', loadChildren: () => import("./modules/quality/quality.module").then(m => m.QualityModule), canActivate: [authGuard] },
  { path: 'projects/messages', loadChildren: () => import("./modules/messages/messages.module").then(m => m.MessagesModule), canActivate: [authGuard] },
  { path: 'projects/Permission', loadChildren: () => import("./modules/permissions/permissions.module").then(m => m.PermissionsModule), canActivate: [authGuard] },
  { path: 'licence', loadChildren: () => import("./modules/License/licence-module/licence.module").then(m => m.LicenceModuleModule), canActivate: [authGuard] },
  { path: 'administration', loadChildren: () => import("./modules/administration/administration.module").then(m => m.AdministrationModule), canActivate: [authGuard], data: { roles: [Role.Admin] } },
  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
