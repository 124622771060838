import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { environment } from 'src/environments/environment';
import { config } from '../../models/config';
import { ApiResult } from '../../models/CoreApi/ApiResult';
import { MessageModel } from '../../models/Message/message.model';
import { NotificationModel, UpdateNotification } from '../../models/Notification/notification.model';
import { ResponseData } from '../../models/ResponseData';
import { AuthService } from '../auth/auth.service';

const auth_Api = environment.serverUrl;
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  noticationdataList: NotificationModel[] = [];
  notificationCount: number = 0

  constructor(private http: HttpClient, public authService: AuthService) { }
  getNotification(): Observable<ApiResult<ResponseData<NotificationModel[]>>> {

    let token = this.authService.getToken();
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: token
    });
    return this.http.get<ApiResult<ResponseData<NotificationModel[]>>>(auth_Api + config.getNotificationDetail, { headers: httpHeaders });
  }
  readNoticaiton(): Observable<ApiResult<ResponseData<boolean>>> {
    let token = this.authService.getToken();
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: token
    });
    return this.http.put<ApiResult<ResponseData<boolean>>>(auth_Api + config.readNotification,{}, { headers: httpHeaders })
  }
  updateNotification(notificationModel: UpdateNotification) {
    let token = this.authService.getToken();
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: token
    });
    return this.http.post<ApiResult<ResponseData<boolean>>>(auth_Api + config.readNotification, notificationModel, { headers: httpHeaders })
  }

  countNotification() {
    let token = this.authService.getToken();
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: token
    });
    return this.http.get<ApiResult<ResponseData<boolean>>>(auth_Api + config.getNotificatonCount, { headers: httpHeaders })
  }
}
