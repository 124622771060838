import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, of, map } from 'rxjs';
import { AuthService } from './core/services/auth/auth.service';
import { Role } from './core/models/Authentication/role';

export const authGuard: CanActivateFn = (route, state) => {

  const authService = inject(AuthService);
  const router = inject(Router);
  var getToken = authService.getToken();
  if (getToken != undefined && getToken != null) {
    var authorized: boolean = true;
    var getUserRole = authService.getUserRole();
    const roles = route.data.roles as Role[];
    if (roles != undefined) {
      for (var role = 0; role < roles.length; role++) {
        if (roles[role] != getUserRole) {
          authorized = false;
          break;
        }
      }
    }
    return authorized;
  }
  else {
    //debugger;
    //router.navigate(['/auth']);
    router.navigate(['/auth'], {
      queryParams: { returnUrl: state.url }
    });
    return false;
  }
};
