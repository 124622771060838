import { Component } from '@angular/core';
import { VendorService } from './core/services/Vendor/vendor.service';
import { GlobalService } from './core/services/global/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app-slm';
  constructor(public globalService:GlobalService){}
}

