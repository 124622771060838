export let config = {
    //auth
    Auth_Login: 'api/Authentication/Login',
    Auth_Register: 'api/Authentication/Register',
    changePassword: 'api/Authentication/ChangePassword',
    forgotPassword: 'api/Authentication/ForgetPassword',
    resetPassword: 'api/Authentication/SetNewPassword',
    mailResend: 'api/Authentication/AccountActivationMail',
    AccountActivation:'api/Authentication/AccountActivate',
    AddUpdateUserStatus:'api/Authentication/AddUpdateUserStatus',


    //Project
    get_Project: 'api/Project/GetProjectList',
    Delete_project: 'api/Project/DeleteProject',
    AddUpdateProject: 'api/Project/AddUpdateProject',
    Close_Project: 'api/Project/UpdateProjectStatus',
    getDetailByProjectId:'api/Project/GetUserProjectById',



    get_ProjectData: 'api/Project/GetProjects',
    get_ProjectstatisticsData: 'api/Project/Projectsstatistics',
    deleteProjectList: 'api/Project/DeleteProjects',
    updateProjectStatusList: 'api/Project/UpdateSelectedProjectStatus',
    //Client Api
    get_Clients: 'api/Client/GetClients',
    get_ClientList: 'api/Client/GetClientList',
    addUpdateClient: 'api/Client/AddUpdateClient',
    DeleteClients: 'api/Client/DeleteClients',

    //support
    get_SupportEmail: 'api/Client/GetSupportEmail',
    Update_SupportEmail: 'api/Client/updateSupportEmail',

    //User Apii
    RegisterUser: 'api/Authentication/Register',

    //Report Api
    Display_Vendor_Report: 'api/Report/DisplayVendorReport',
    Download_Project_Report: 'api/Report/DownloadAllReport',
    get_All_Reports: 'api/Report/ProjectStatistic',
    getDeownloadMultipleReports: 'api/Report/DownloadSelectedReports',
    Download_Project_Reports: 'api/Report/DownloadAllReports',
    DownloadSchedulerReports: 'api/Report/DownloadSchedulerReports',
    DownloadDFPLog: 'api/Report/DownloadDFPLog',
    //getAllReportVendor:'api/Report/DownloadAllReport',

    //Vendor Api.......
    get_Vendor_Detail: 'api/Vendor/GetVendorDetails',
    addUpdateLink: 'api/Vendor/AddUpdateLinks',
    addUpdateEndLink: 'api/Vendor/AddUpdateEndLinks',
    getEndlinkDetail: 'api/Vendor/GetManageEndLinks',
    downloadStartLink: 'api/Vendor/DownloadVendorLinks',
    downloadEndLinkTemplate: 'api/Vendor/DownloadEndLinks',
    deleteVendorByIds: 'api/Vendor/DeleteVendors',
    deleteVendor: 'api/Vendor/DeleteVendor',
    getManageVendorDetailById: 'api/Vendor/GetVendorById',
    copyEndLinks: 'api/Vendor/CopyEndLinks',
    VendorTemplate: 'api/Vendor/DownloadVendorTemplate',

    //Permission Api.....
    get_UserPermissionRecord: 'api/Permission/GetPermissionRecordByProject',
    saveUpdatePermission: 'api/Permission/SaveAndUpdatePermission',
    get_GroupPermissionRecord: 'api/Group/GroupPermissionListByProject',
    manageGroupAccess: 'api/Group/ManageGroupAccess',
    DeleteGroups: 'api/Group/DeleteGroups',



    //DataUpdateApi.....
    get_RespondentStatusGrid: 'api/DataUpdate/GetRespondentStatusGrid',
    updateResponseType: 'api/DataUpdate/UpdateDataByGrid',
    downloadTemplate: 'api/DataUpdate/DownloadTemplate',
    moveResponseToNewStatus: 'api/DataUpdate/MoveResponseToNewStatus',
    csvUploadDataUpdate: 'api/DataUpdate/UpdateDataByExcel',


    //MessageApi.....
    addUpdateMessage: 'api/Message/AddUpdateMessage',
    getMessage: 'api/Message/GetMessages',
    getMessageStatus: 'api/Message/GetProjectMessageStatus',
    deleteMsg: 'api/Message/RemoveMessage',




    //Quality......
    addUpdateQuotaFull: 'api/Quality/SaveandUpdateManageQuotaFull',
    getQuotaFullDataById: 'api/Quality/ManageQuotaFull',
    SavequlaityreportData: 'api/Quality/SaveUpdateQualityReport',
    getQualityReport: 'api/Quality/getQualityReport',


    //Group.......
    getGroup: 'api/Group/GetGroups',
    getGroupList: 'api/Group/GetGroupsList',
    addUpdateGroup: 'api/Group/AddUpdateGroup',
    getGroupById: 'api/Group/GetGroupById',


    //Users.......
    getUsersList: 'api/User/UserList',
    getUsers: 'api/User/GetUsers',
    getUsersById: 'api/User/GetUserAddEdit',
    UpdateUsersById:'api/User/UserUpdate',
    GetUserByIDForEditDetail:'api/User/GetUserForUpdate',

    // lince
    getLicenceDetail: 'api/License/GetLicenceDetail',

    //Notification
    getNotificationDetail: 'api/Notification/GetNotifications',
    readNotification: 'api/Notification/NotificationRead',
    updateNotification: 'api/Notification/UpdateNotification',
    getNotificatonCount:'api/Notification/GetUnreadNotificationCount',

    //Dfp
    addUpdateDfp: 'api/Dfp/SaveAndUpdateDfp',
    getCountryList: 'api/Dfp/GetCountries',
    getdfpData: 'api/Dfp/GetManageDfp',



    //Dashboard
    GetDashboardReport:'api/Dashboard/GetDashboardReport',
    GetDashboardDataByDeviceType:'api/Dashboard/GetDashboardDataByDeviceType',
    GetRestrictedCountries:'api/Dashboard/GetRestrictedCountries',
    GetRestrictedResQScore:'api/Dashboard/GetRestrictedResQScore',
    GetRestrictedIP:'api/Dashboard/GetRestrictedByIP',
    GetAllCountryData:'api/Dashboard/GetAllCountryData'


}
