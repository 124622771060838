import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CopyEndLinks } from '../../models/Vendor/vendor.model';
import { ClipboardService } from 'ngx-clipboard'
import { Message } from '../../ErrorMessages/Error';
import { ToastService } from '../toast/toast.service';
import { NotificationService } from '../Notification/notification.service';
import { Subject } from 'rxjs';

//add by sachin
// import { Observable } from 'rxjs';
// import { ApiResult } from '../../models/CoreApi/ApiResult';
// import { ResponseData } from '../../models/ResponseData';
// import { AuthService } from '../auth/auth.service';
// import { environment } from 'src/environments/environment.development';
// import { config } from '../../models/config';
@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(
    public router: Router,
    private _clipboardService: ClipboardService,
    public toastService: ToastService,
    private noticationService: NotificationService,
    private http: HttpClient,
    //public authService: AuthService
  ) { }

  ApiResponseCode: number[] = [Message.BadRequest, Message.Forbidden, Message.NotFound, Message.MethodeNotAllowed, Message.NotAcceptable, Message.RequestTimeOut,
  Message.internalServerError, Message.NotImplemented, Message.NotImplemented, Message.ServiceUnavailable];

  fileName: string;
  linkManagementChange: boolean = false;
  Captchavalue: string;
  isDFPAllowed: boolean = false;
  isLoader: boolean = false;
  userCount:any=0;
  groupCount:any=0;
  cleintCount:any=0;
  //auth_Api = environment.serverUrl;
  private innerComponentSubject = new Subject<void>();
  innerComponentAction$ = this.innerComponentSubject.asObservable();
  triggerInnerComponentAction() {
    this.innerComponentSubject.next();
  }


  redirectToLoginPage() {
    this.router.navigate(['/auth']);
  }

  DownloadCsv(dataCsv: any, csvName: string) {
    var filename = csvName + '.csv';
    var byteCharacters = atob(dataCsv);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
      type: "application/text/csv",
    });

    const fileURL = URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = fileURL;
    a.type = "application/vtext/csv";
    a.download = filename;
    a.click();
  }

  getCurrentDate() {
    let getDate = new Date().toLocaleDateString();
    let getTime = new Date().toLocaleTimeString();
    let DateWithTime = getDate + '_' + getTime.toString();
    return DateWithTime;

  }

  copyVendorLink(linkData: CopyEndLinks) {
    var _msg = "Complete : " + linkData.complete + "\n";
    _msg += "Quality : " + linkData.quality + "\n";
    _msg += "Quotafull : " + linkData.quotaFull + "\n";
    _msg += "Screened : " + linkData.screened;
    this._clipboardService.copy(_msg);
  }

  truncateText(text: string): string {
    let limit: number = 70
    if (text.length <= limit) {
      return text;
    } else {
      return text.slice(0, limit) + '...';
    }
  }

  truncateTextSmallTile(text: string): string {
    let limit: number = 20
    if (text.length <= limit) {
      return text;
    } else {
      return text.slice(0, limit) + '...';
    }
  }

  handleError(error) {
    this.isLoader = false;
    // Handle error response here
    if (error.status == Message.Authorized)
      this.redirectToLoginPage();
    else
      this.toastService.Toast("Something went wrong.", this.toastService.errorToastType, this.toastService.errorTitle)
  }


  getNotification() {
    this.noticationService.noticationdataList = []
    this.noticationService.getNotification().subscribe({
      next: data => {
        if (data != null && data.hasSuccess && data.dataObject != null) {
          if (data.dataObject.data != null && data.dataObject.data.length > 0) {
            this.noticationService.noticationdataList = data.dataObject.data;
          }
        }
        else {
          this.toastService.Toast(data.resultCode.messageText, this.toastService.errorToastType, this.toastService.errorTitle)
        }
      }
      , error: err => {
        this.handleError(err)
      }
    })

  }

}
